const Chip = {
	MuiChip: {
		styleOverrides: {
			label: {
				color: '#000',
			},
		},
	},
}

export default Chip
