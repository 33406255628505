const Badge = {
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				border: '2px solid #fff',
			},
		},
	},
}

export default Badge
