const BHRelated = {
	BHRelated: {
		defaultProps: {
			sliderProps: {
				breakpoints: {
					xs: { spaceBetween: 10 },
					md: {
						spaceBetween: 16,
					},
				},
				controlsPosition: 'default',
				spaceBetween: 10,
			},
		},
	},
}

export default BHRelated
