import { fonts } from '../fonts'

const Tooltip = {
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				maxWidth: 350,
				padding: 15,
				boxShadow: 'none',
				fontFamily: fonts.regular,
			},
		},
	},
}

export default Tooltip
