import { createTheme } from '@mui/system'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import defaultTheme from '@bluheadless/ui/src/theme/default'
import Button, { buttonFontSize } from './theme/components/button'
import Badge from './theme/components/badge'
import Input from './theme/components/form/input'
import Tooltip from './theme/components/tooltip'
import CssBaseline from './theme/components/css-baseline'
import { fontSize, htmlFontSize, fonts } from './theme/fonts'
import BHRelatedAttributes from './theme/components/bh-related-attributes'
import BHBreadcrumbs from './theme/components/bh-breadcrumbs'
import BHRelated from './theme/components/bh-related'
import BHUpsells from './theme/components/bh-upsells'
import BHTileBase from './theme/components/bh-tile-base'
import Chip from './theme/components/chip'
import Container from './theme/components/container'

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.poppins
const fontFamilyAlternative = fonts.poppins

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000',
			white: '#fff',
		},
		footer: {
			bgMiddleTop: '#000',
			bgMiddleBottom: '#000',
			bgBottom: '#000',
			color: '#fff',
			border: '#000',
		},
		primary: {
			main: '#000',
		},
		secondary: {
			main: '#fff',
			light: '#fff',
			dark: '#024ED0',
			contrastText: '#000',
		},
		text: {
			primary: '#000000',
			secondary: '#F8F2E4',
			disabled: '#fafafa',
		},
		grey: {
			lighter: '#F7F7F7',
			light: '#E5E5E5',
			medium: '#B1B1B1',
			main: '#777777',
			dark: '#818181',
			disabled: '#E3E3E3',
			active: '#ECECEC',
		},
		background: {
			card: '#F6F6F6',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: fonts.ogg,
			fontWeight: 700,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1,
			letterSpacing: '1.25px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h2: {
			fontFamily: fonts.ogg,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(40),
			lineHeight: 1.185,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(60),
			},
		},
		h3: {
			fontFamily: fonts.ogg,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1.167,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		h4: {
			fontFamily: fonts.ogg,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 1.235,
			letterSpacing: '1px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
		},
		h5: {
			fontFamily: fonts.poppins,
			fontWeight: 700,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1.2,
			letterSpacing: '0.3px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
			},
		},
		h6: {
			fontFamily: fonts.poppins,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: '23px',
			letterSpacing: '0.2px',
		},
		body1: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		body2: {
			fontFamily: fonts.poppins,
			fontWeight: 600,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		p: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		subtitle1: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		subtitle2: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		button: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: buttonFontSize,
			lineHeight: 1.3,
			letterSpacing: '0.2px',
			borderRadius: 40,
		},
		caption: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.4,
			letterSpacing: '0.2px',
		},
		overline: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 2.66,
			letterSpacing: '0.2px',
			textTransform: 'uppercase',
		},
		headlinebig: {
			fontFamily: fonts.ogg,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: 1,
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(60),
			},
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(80),
				lineHeight: '80px',
			},
		},
		headlinemedium: {
			fontFamily: fonts.ogg,
			fontWeight: 500,
			fontSize: muiTheme.typography.pxToRem(40),
			lineHeight: '45px',
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(50),
				lineHeight: '70px',
			},
		},
		headlinesmall: {
			fontFamily: fonts.ogg,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(24),
			lineHeight: 1,
			letterSpacing: '1.25px',
		},
		subheadline1: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '24px',
			letterSpacing: '0.28px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
				lineHeight: '35px',
				letterSpacing: '1px',
			},
		},
		subheadline2: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(10),
			lineHeight: '35px',
			letterSpacing: '4px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(12),
				lineHeight: '35px',
				letterSpacing: '4.8px',
			},
		},
		checkbox: {
			fontFamily: fonts.poppins,
			fontWeight: 400,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: '16px',
			letterSpacing: '0.2px',
		},
	},
	components: {
		...CssBaseline,
		...Button,
		...Tooltip,
		...Badge,
		...BHRelatedAttributes,
		...BHBreadcrumbs,
		...BHRelated,
		...BHUpsells,
		...BHTileBase,
		...Chip,
		...Container,
		...Input,
	},
})

export default theme
