const Input = {
	MuiInputLabel: {
		styleOverrides: {
			root: {
				color: '#818181',
				fontWeight: 500,
				fontSize: '14px',
				letterSpacing: '0.7px',
			},
		},
	},
}
export default Input
