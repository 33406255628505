const BHBreadcrumbs = {
	MuiBreadcrumbs: {
		styleOverrides: {
			root: {
				lineHeight: 1,
				marginBottom: 0,
				padding: 0,
			},
			separator: {
				color: '#afafaf',
			},
		},
	},
}

export default BHBreadcrumbs
