import defaultTheme from '@bluheadless/ui/src/theme/default'

const Container = {
	MuiContainer: {
		styleOverrides: {
			root: {
				paddingLeft: defaultTheme.spacing(3),
				paddingRight: defaultTheme.spacing(3),
				[defaultTheme.breakpoints.up('lg')]: {
					paddingLeft: defaultTheme.spacing(4),
					paddingRight: defaultTheme.spacing(4),
				},
			},
			disableGutters: {
				paddingLeft: 0,
				paddingRight: 0,
				[defaultTheme.breakpoints.up('lg')]: {
					paddingLeft: 0,
					paddingRight: 0,
				},
			},
		},
	},
}

export default Container
