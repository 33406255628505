import { fonts } from '../fonts'
import { alpha } from '@mui/system'

export const buttonFontSize = 12
export const buttonIconFontSizeMedium = 20

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: fonts.regular,
				fontSize: buttonFontSize,
				textTransform: 'uppercase',
				fontWeight: 600,
				letterSpacing: '0.6px',
				padding: '12px 20px',
			},
			sizeMedium: {
				'& .MuiButton-iconSizeMedium.MuiButton-startIcon': {
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: buttonIconFontSizeMedium,
					},
				},
			},
			text: {
				textTransform: 'none',
				paddingBottom: '1px',
			},
			containedPrimary: {
				minHeight: 45,
			},
			containedSecondary: {
				color: '#000000',
				minHeight: 45,
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: '#000000',
					color: '#ffffff',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: '#000000',
					color: '#ffffff',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: '#000000',
					color: '#ffffff',
				},
				'&.Mui-disabled': {
					color: '#000000',
					backgroundColor: alpha('#000000', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: '#000000',
				},
				'& >svg': {
					color: '#000000',
				},
				'& .MuiButton-endIcon': {
					fill: '#000000',
				},
			},
			outlined: {
				borderRadius: '45px',
				borderWidth: '2px',
				padding: '12px 20px',
				minHeight: 45,
				fontWeight: 700,
				'&:hover': {
					borderWidth: '2px',
				},
				'&:focus': {
					borderWidth: '2px',
				},
				'&:active': {
					borderWidth: '2px',
				},
				'&.Mui-disabled': {
					borderWidth: '2px',
				},
			},
			outlinedPrimary: {
				color: '#000000',
				background: 'transparent',
				borderColor: '#000000',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: '#000000',
					color: '#ffffff',
					borderColor: '#000000',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: '#000000',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: '#000000',
				},
				'&.Mui-disabled': {
					color: '#000000',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: '#000000',
				},
				'& >svg': {
					color: '#000000',
				},
				'& .MuiButton-endIcon': {
					fill: '#000000',
				},
			},
			outlinedSecondary: {
				color: '#ffffff',
				background: 'transparent',
				borderColor: '#ffffff',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: '#ffffff',
					color: '#000000',
					borderColor: '#ffffff',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'transparent',
					borderColor: '#ffffff',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: '#ffffff',
					borderColor: '#ffffff',
					color: '#000000',
				},
				'&.Mui-disabled': {
					color: '#000000',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: '#ffffff',
				},
				'& >svg': {
					color: '#ffffff',
				},
				'& .MuiButton-endIcon': {
					fill: '#ffffff',
				},
			},
			contained: {
				borderRadius: 30,
			},
			textNoLinkPrimary: {
				fontFamily: fonts.poppins,
				color: '#000',
			},
		},
	},
}

export default Button
